import { Language } from "../LangSelector/types";
import { DiscordIcon, GithubIcon, TelegramIcon, TwitterIcon } from "../Svg";
import { FooterLinkType } from "./types";

export const footerLinks: FooterLinkType[] = [];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/astraswap0",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/astraswap",
  },
  {
    label: "Discord",
    icon: DiscordIcon,
    href: "#",
  },
  {
    label: "Github",
    icon: GithubIcon,
    href: "https://github.com/astraswap",
  },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
