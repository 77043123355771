import { zeusTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0x173b37ae4420e0d08a549e854b69cb3f5ffbba24',
    token0: zeusTokens.weth,
    token1: zeusTokens.astra,
    feeAmount: FeeAmount.MEDIUM,
  }
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
