import { ChainId } from '@pancakeswap/chains'
import { ERC20Token } from '@pancakeswap/sdk'
import {
  arbitrumTokens,
  zeusTokens,
  pulseTokens,
  bscTestnetTokens,
  bscTokens,
  ethereumTokens,
  goerliTestnetTokens,
  lineaTokens,
  opBnbTokens,
  polygonZkEvmTokens,
  zkSyncTestnetTokens,
  zksyncTokens,
  uniSepTokens,
  worldChainTokens,
  morphChainTokens,
  inkChainTokens,
  apeChainTokens,
  baseChainTokens
} from '@pancakeswap/tokens'
import type { FarmV3SupportedChainId } from '../../src'
import type { CommonPrice } from '../../src/fetchFarmsV3'

export const CAKE_BNB_LP_MAINNET = '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0'

export type PriceHelper = {
  chain: string
  list: ERC20Token[]
}

export const priceHelperTokens = {
  [ChainId.ETHEREUM]: {
    chain: 'ethereum',
    list: [ethereumTokens.weth, ethereumTokens.usdc, ethereumTokens.usdt],
  },
  [ChainId.BSC]: {
    chain: 'bsc',
    list: [bscTokens.wbnb, bscTokens.usdt, bscTokens.busd, bscTokens.eth],
  },
  [ChainId.POLYGON_ZKEVM]: {
    chain: 'polygon_zkevm',
    list: [polygonZkEvmTokens.weth, polygonZkEvmTokens.usdc, polygonZkEvmTokens.usdt, polygonZkEvmTokens.matic],
  },
  [ChainId.ZKSYNC]: {
    chain: 'zksync',
    list: [zksyncTokens.weth, zksyncTokens.usdc, zksyncTokens.usdt],
  },
  [ChainId.ARBITRUM_ONE]: {
    chain: 'arbitrum',
    list: [arbitrumTokens.weth, arbitrumTokens.usdc, arbitrumTokens.usdt, arbitrumTokens.arb, arbitrumTokens.usdplus],
  },
  [ChainId.LINEA]: {
    chain: 'linea',
    list: [lineaTokens.weth, lineaTokens.usdc, lineaTokens.usdt, lineaTokens.wbtc, lineaTokens.dai],
  },
  [ChainId.ZEUSCHAIN]: {
    chain: 'zeuschain',
    list: [zeusTokens.weth],
  },
  [ChainId.PULSECHAIN]: {
    chain: 'pulsechain',
    list: [pulseTokens.weth,pulseTokens.dai,pulseTokens.usdt],
  },
  [ChainId.UNICHAIN_SEPOLIA]: {
    chain: 'unichainsepolia',
    list: [uniSepTokens.weth,uniSepTokens.dai,uniSepTokens.usdt],
  },
  [ChainId.WORLDCHAIN]: {
    chain: 'worldchain',
    list: [worldChainTokens.weth,worldChainTokens.usdc],
  },
  [ChainId.MORPHCHAIN]: {
    chain: 'morphchain',
    list: [morphChainTokens.weth,morphChainTokens.usdt],
  },
  [ChainId.INKCHAIN]: {
    chain: 'inkchain',
    list: [inkChainTokens.weth,inkChainTokens.dai],
  },
  [ChainId.APECHAIN]: {
    chain: 'apechain',
    list: [apeChainTokens.weth,apeChainTokens.usdc],
  },
  [ChainId.BASECHAIN]: {
    chain: 'basechain',
    list: [baseChainTokens.weth,baseChainTokens.usdc],
  },
  [ChainId.OPBNB]: {
    chain: 'opbnb',
    list: [opBnbTokens.wbnb, opBnbTokens.usdt],
  },
} satisfies Record<number, PriceHelper>

// for testing purposes
export const DEFAULT_COMMON_PRICE: Record<FarmV3SupportedChainId, CommonPrice> = {
  [ChainId.ETHEREUM]: {},
  [ChainId.GOERLI]: {
    [goerliTestnetTokens.mockA.address]: '10',
  },
  [ChainId.BSC]: {},
  [ChainId.BSC_TESTNET]: {
    [bscTestnetTokens.mockA.address]: '10',
    [bscTestnetTokens.usdt.address]: '1',
    [bscTestnetTokens.busd.address]: '1',
    [bscTestnetTokens.usdc.address]: '1',
  },
  [ChainId.ZKSYNC_TESTNET]: {
    [zkSyncTestnetTokens.mock.address]: '10',
  },
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.ZKSYNC]: {},
  [ChainId.POLYGON_ZKEVM_TESTNET]: {},
  [ChainId.ARBITRUM_ONE]: {},
  [ChainId.LINEA]: {},
  [ChainId.ZEUSCHAIN]: {},
  [ChainId.UNICHAIN_SEPOLIA]: {},
  [ChainId.WORLDCHAIN]: {},
  [ChainId.MORPHCHAIN]: {},
  [ChainId.INKCHAIN]: {},
  [ChainId.APECHAIN]: {},
  [ChainId.BASECHAIN]: {},
  [ChainId.PULSECHAIN]: {},
  [ChainId.OPBNB_TESTNET]: {},
  [ChainId.OPBNB]: {},
}
