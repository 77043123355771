import { baseChainTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0x5301f41e1a0c0af0c6d421926234452acf0e2ee8',
    token0: baseChainTokens.weth,
    token1: baseChainTokens.usdc,
    feeAmount: FeeAmount.MEDIUM,
  },

]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
