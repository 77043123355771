import { inkChainTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0xFbCe935c2dBC596dD9772B209717416FB0e0f91c',
    token0: inkChainTokens.weth,
    token1: inkChainTokens.astra,
    feeAmount: FeeAmount.MEDIUM,
  }
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
