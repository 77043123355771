import { uniSepTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0xbc46692325e4c4bC2a710313DE4495AFf7715Dfe',
    token0: uniSepTokens.weth,
    token1: uniSepTokens.astra,
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 2,
    lpAddress: '0x2e05da21c5f68fde220635ec072f91e2336ad66e',
    token0: uniSepTokens.dai,
    token1: uniSepTokens.weth,
    feeAmount: FeeAmount.MEDIUM,
  }
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
