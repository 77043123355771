import { worldChainTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0x389D3fEAdf2d6803D11207E82d73D0F2fafB22F0',
    token0: worldChainTokens.weth,
    token1: worldChainTokens.astra,
    feeAmount: FeeAmount.MEDIUM,
  }
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
