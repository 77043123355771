import { apeChainTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0xBf66facAf6FD32AC5A62402bdE3cDE3F988Cd020',
    token0: apeChainTokens.weth,
    token1: apeChainTokens.astra,
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 2,
    lpAddress: '0x8C1337410CcC313FA7ECc4f70e7402FE44c59F94',
    token0: apeChainTokens.weth,
    token1: apeChainTokens.usdc,
    feeAmount: FeeAmount.MEDIUM,
  }
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
