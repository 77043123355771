import { morphChainTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0x1A523CeF3A2F00ADec494A30Afcb59d04A9f4f29',
    token0: morphChainTokens.weth,
    token1: morphChainTokens.usdt,
    feeAmount: FeeAmount.MEDIUM,
  }
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
