import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | AstraSwap',
  defaultTitle: 'AstraSwap',
  description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@astraswap0',
    site: '@astraswap0',
  },
  openGraph: {
    title: "AstraSwap Exchange - Everyone's Favorite Multichain DEX",
    description: 'Trade, earn, and own crypto on the all-in-one Astra DEX',
    images: [{ url: 'https://astraswap.io/static/og.png' }],
  },
}
